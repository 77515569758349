import React, { useCallback, useEffect, useState } from "react";
import { ChevronDown, GraduationCap, Plus, Search, X } from "lucide-react";
import { ContentService, IContentListEntry } from "../services/ContentService";
import ContentListEntryComponent from "./ContentListEntryComponent";
import Login from "./Login";
import AutoSubmitForm from "./AutoSubmitForm";

interface DeepLinkContentListProps {
  contentService: ContentService;
}

const h5pContentTypeMap: Record<string, string> = {
  "H5P.InteractiveVideo": "Video tương tác",
  "H5P.QuestionSet": "Bộ câu hỏi",
  "H5P.MultiChoice": "Câu hỏi nhiều lựa chọn",
  "H5P.DragQuestion": "Kéo và thả",
  "H5P.DragAndDrop": "Kéo và thả nâng cao",
  "H5P.MarkTheWords": "Đánh dấu từ",
  "H5P.Blanks": "Điền vào chỗ trống",
  "H5P.Essay": "Viết luận",
  "H5P.Summary": "Tóm tắt",
  "H5P.Column": "Cột nội dung",
  "H5P.CoursePresentation": "Bài giảng tương tác",
  "H5P.Flashcards": "Thẻ ghi nhớ",
  "H5P.DocumentationTool": "Công cụ tài liệu",
  "H5P.ImageHotspots": "Điểm nóng trên ảnh",
  "H5P.Timeline": "Dòng thời gian",
  "H5P.ImageSlider": "Trình chiếu ảnh",
  "H5P.ImageSequencing": "Sắp xếp ảnh",
  "H5P.ImagePair": "Ghép cặp ảnh",
  "H5P.ImageJuxtaposition": "So sánh ảnh",
  "H5P.MemoryGame": "Trò chơi trí nhớ",
  "H5P.Dialogcards": "Thẻ đối thoại",
  "H5P.SingleChoiceSet": "Bộ câu hỏi một lựa chọn",
  "H5P.SpeakTheWords": "Nói các từ",
  "H5P.Audio": "Âm thanh",
  "H5P.AudioRecorder": "Ghi âm",
  "H5P.Accordion": "Menu xổ xuống",
  "H5P.Agamotto": "So sánh ảnh theo thời gian",
  "H5P.ArithmeticQuiz": "Câu đố toán học",
  "H5P.BranchingScenario": "Kịch bản phân nhánh",
  "H5P.Chart": "Biểu đồ",
  "H5P.Collage": "Ảnh ghép",
  "H5P.FindTheWords": "Tìm từ",
  "H5P.GuessTheAnswer": "Đoán câu trả lời",
  "H5P.IframePoster": "Poster có khung",
  "H5P.ImportantInformation": "Thông tin quan trọng",
  "H5P.TrueFalse": "Đúng/Sai",
  "H5P.DragText": "Kéo thả văn bản",
  "H5P.CrossWord": "Ô chữ",
};

// Function to get friendly name
const getFriendlyContentTypeName = (libraryName: string): string => {
  return h5pContentTypeMap[libraryName] || libraryName;
};

const DeepLinkContentList: React.FC<DeepLinkContentListProps> = ({
  contentService,
}) => {
  const [contentList, setContentList] = useState<IContentListEntry[]>([]);
  const [newCounter, setNewCounter] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLibrary, setSelectedLibrary] = useState<string>("");
  const [isLibraryDropdownOpen, setIsLibraryDropdownOpen] = useState(false);
  const [deeplinkData, setDeeplinkData] = useState<{
    action: string;
    jwtToken: string;
  } | null>(null);
  const [editingContent, setEditingContent] = useState<{
    data: IContentListEntry;
    isOpen: boolean;
  } | null>(null);

  // Get unique libraries from content list
  const uniqueLibraries = Array.from(
    new Set(contentList.map((content) => content.mainLibrary)),
  ).filter(Boolean);

  const handleDeeplink = async (contentId: string, title: string) => {
    try {
      const { action, jwt } = await contentService.deeplinkResource(
        contentId,
        title,
      );
      setDeeplinkData({ action, jwtToken: jwt });
    } catch (error) {
      console.error("Error creating deeplink:", error);
      alert("Failed to create deeplink. Please try again.");
    }
  };

  const updateList = useCallback(async () => {
    try {
      setIsLoading(true);
      const list = await contentService.listTeacher();
      setContentList(list);
    } catch (error) {
      console.error("Error fetching content list:", error);
    } finally {
      setIsLoading(false);
    }
  }, [contentService]);

  useEffect(() => {
    if (loggedIn) {
      updateList();
    } else {
      setContentList([]);
    }
  }, [loggedIn, updateList]);

  const handleNew = () => {
    const newItem: IContentListEntry = {
      contentId: "new",
      mainLibrary: "",
      title: "H5P Mới",
      originalNewKey: `new-${newCounter}`,
    };
    setNewCounter((prev) => prev + 1);
    setEditingContent({ data: newItem, isOpen: true });
  };

  const handleDiscard = (content: IContentListEntry) => {
    if (content.contentId === "new") {
      setEditingContent(null);
    }
  };

  const handleDelete = async (content: IContentListEntry) => {
    if (!content.contentId || content.contentId === "new") return;
    try {
      await contentService.delete(content.contentId);
      setContentList((prevList) => prevList.filter((c) => c !== content));
    } catch (error) {
      console.error(error instanceof Error ? error.message : "Đã xảy ra lỗi");
    }
  };

  const handleSaved = async (
    oldData: IContentListEntry,
    newData: IContentListEntry,
  ) => {
    if (oldData.contentId === "new") {
      setContentList((prevList) => [newData, ...prevList]);
    } else {
      setContentList((prevList) =>
        prevList.map((item) => (item === oldData ? newData : item)),
      );
    }
    setEditingContent(null);
    await updateList();
  };

  const handleLogin = () => setLoggedIn(true);
  const handleLogout = () => {
    setLoggedIn(false);
    setContentList([]);
    setEditingContent(null);
  };

  const uniqueLibrariesWithFriendlyNames = Array.from(
    new Set(contentList.map((content) => content.mainLibrary)),
  )
    .filter(Boolean)
    .map((library) => ({
      original: library,
      friendly: getFriendlyContentTypeName(library),
    }))
    .sort((a, b) => a.friendly.localeCompare(b.friendly));

  // Update the filtered content logic
  const filteredContent = contentList.filter((content) => {
    const matchesSearch =
      content.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      getFriendlyContentTypeName(content.mainLibrary)
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

    const matchesLibrary =
      !selectedLibrary || content.mainLibrary === selectedLibrary;

    return matchesSearch && matchesLibrary;
  });

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".library-dropdown")) {
        setIsLibraryDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="flex h-screen">
      <div className="absolute top-4 left-4">
        <Login
          contentService={contentService}
          onLoggedIn={handleLogin}
          onLoggedOut={handleLogout}
        />
      </div>

      {loggedIn && isModalOpen && (
        <div className="w-full max-w-4xl mx-auto my-8 bg-white rounded-lg shadow-lg flex flex-col h-[calc(100vh-4rem)]">
          <div className="flex-none">
            <div className="p-4 border-b">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                  <GraduationCap className="text-blue-600" size={24} />
                  <h2 className="text-lg font-semibold">
                    Công cụ tạo nội dung tương tác (Giáo viên)
                  </h2>
                </div>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X size={20} />
                </button>
              </div>

              <div className="flex gap-4">
                <div className="flex-1 relative">
                  <Search
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={20}
                  />
                  <input
                    type="text"
                    placeholder="Tìm kiếm"
                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="relative library-dropdown">
                  <button
                    className="px-4 py-2 border rounded-lg flex items-center gap-2 hover:bg-gray-50"
                    onClick={() =>
                      setIsLibraryDropdownOpen(!isLibraryDropdownOpen)
                    }
                  >
                    {selectedLibrary
                      ? getFriendlyContentTypeName(selectedLibrary)
                      : "Loại nội dung"}
                    <ChevronDown size={20} />
                  </button>
                  {isLibraryDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-64 bg-white border rounded-lg shadow-lg z-50">
                      <div className="py-1">
                        <button
                          className="w-full px-4 py-2 text-left hover:bg-gray-50"
                          onClick={() => {
                            setSelectedLibrary("");
                            setIsLibraryDropdownOpen(false);
                          }}
                        >
                          Tất cả
                        </button>
                        {uniqueLibrariesWithFriendlyNames.map(
                          ({ original, friendly }) => (
                            <button
                              key={original}
                              className="w-full px-4 py-2 text-left hover:bg-gray-50"
                              onClick={() => {
                                setSelectedLibrary(original);
                                setIsLibraryDropdownOpen(false);
                              }}
                            >
                              {friendly}
                            </button>
                          ),
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center p-4 border-b bg-white">
              <h3 className="text-lg font-medium">Danh sách nội dung</h3>
              <button
                onClick={handleNew}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 flex items-center gap-2"
              >
                <Plus size={20} />
                Tạo mới
              </button>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto">
            {isLoading ? (
              <div className="flex justify-center items-center py-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
              </div>
            ) : (
              <div className="p-4 space-y-3">
                {filteredContent.map((content) => (
                  <div key={content.originalNewKey ?? content.contentId}>
                    <ContentListEntryComponent
                      contentService={contentService}
                      data={content}
                      onDiscard={() => handleDiscard(content)}
                      onDeeplink={handleDeeplink}
                      onDelete={() => handleDelete(content)}
                      onSaved={(newData) => handleSaved(content, newData)}
                      generateDownloadLink={contentService.generateDownloadLink}
                      isDeeplink={true}
                      initialEditMode={editingContent?.data === content}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Form for handling deeplink data */}
      {deeplinkData && (
        <AutoSubmitForm
          action={deeplinkData.action}
          jwtToken={deeplinkData.jwtToken}
        />
      )}

      {!loggedIn && (
        <div className="w-full flex items-center justify-center">
          <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded">
            Vui lòng đăng nhập với tài khoản giáo viên
          </div>
        </div>
      )}
      {/* Add this before the closing div */}
      {editingContent && (
        <ContentListEntryComponent
          contentService={contentService}
          data={editingContent.data}
          onDiscard={() => setEditingContent(null)}
          onDeeplink={handleDeeplink}
          onDelete={() => setEditingContent(null)}
          onSaved={(newData) => handleSaved(editingContent.data, newData)}
          generateDownloadLink={contentService.generateDownloadLink}
          isDeeplink={true}
          initialEditMode={true}
        />
      )}
    </div>
  );
};

export default DeepLinkContentList;
