import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import ContentListComponent from "./components/ContentListComponent";
import DeepLinkContentList from "./components/DeepLinkContentListComponent";
import AutoPlayH5PPlayer from "./components/AutoPlayH5PPlayerComponent"; // Make sure to import this component
import { ContentService } from "./services/ContentService";
import "./App.css";

const LTIRoute: React.FC<{ contentService: ContentService }> = ({
  contentService,
}) => {
  const [searchParams] = useSearchParams();
  const contentId = searchParams.get("contentId");

  if (contentId) {
    return (
      <AutoPlayH5PPlayer
        contentId={contentId}
        contentService={contentService}
      />
    );
  } else {
    return <ContentListComponent contentService={contentService} />;
  }
};

const App: React.FC = () => {
  const contentService = new ContentService("/h5p");

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/lti/deeplink"
            element={<DeepLinkContentList contentService={contentService} />}
          />
          <Route
            path="/lti"
            element={<LTIRoute contentService={contentService} />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
