import React, { useEffect, useRef } from 'react';

interface AutoSubmitFormProps {
  action: string;
  jwtToken: string;
}

const AutoSubmitForm: React.FC<AutoSubmitFormProps> = ({ action, jwtToken }) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit();
    }
  }, []);

  return (
    <form ref={formRef} id="ltijs_submit" style={{ display: 'none' }} action={action} method="POST">
      <input type="hidden" name="JWT" value={jwtToken} />
    </form>
  );
};

export default AutoSubmitForm;