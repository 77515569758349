import type {
  IContentMetadata,
  IEditorModel,
  IPlayerModel,
} from "@lumieducation/h5p-server";
import { getLtikFromUrl } from "../helper";

export interface IContentListEntry {
  contentId: string;
  mainLibrary: string;
  title: string;
  originalNewKey?: string;
}

export interface IContentService {
  delete(contentId: string): Promise<void>;
  getEdit(contentId: string): Promise<IEditorModel>;
  getPlay(
    contentId: string,
    contextId?: string,
    asUserId?: string,
    readOnlyState?: boolean,
  ): Promise<IPlayerModel>;
  list(): Promise<IContentListEntry[]>;
  save(
    contentId: string,
    requestBody: { library: string; params: any },
  ): Promise<{ contentId: string; metadata: IContentMetadata }>;
  generateDownloadLink(contentId: string): string;
}

export class ContentService implements IContentService {
  /**
   *
   */
  constructor(protected baseUrl: string = "") {}

  private csrfToken: string | undefined = undefined;

  delete = async (contentId: string): Promise<void> => {
    console.log(`ContentService: deleting ${contentId}...`);
    const result = await fetch(`${this.baseUrl}/${contentId}`, {
      method: "delete",
      headers: {
        "CSRF-Token": this.csrfToken ?? "",
      },
    });
    if (!result.ok) {
      throw new Error(
        `Error while deleting content: ${result.status} ${
          result.statusText
        } ${await result.text()}`,
      );
    }
  };

  getEdit = async (contentId: string): Promise<IEditorModel> => {
    console.log(`ContentService: Getting information to edit ${contentId}...`);
    const res = await fetch(`${this.baseUrl}/${contentId}/edit`, {
      headers: {
        "Content-Type": "application/json",
        "CSRF-Token": this.csrfToken ?? "",
      },
    });
    if (!res || !res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  };

  getPlay = async (
    contentId: string,
    contextId?: string,
    asUserId?: string,
    readOnlyState?: boolean,
  ): Promise<IPlayerModel> => {
    console.log(
      `ContentService: Getting information to play ${contentId}${
        contextId ? `, contextId ${contextId}` : ""
      }${asUserId ? `, asUserId ${asUserId}` : ""}${
        readOnlyState !== undefined ? `, readOnlyState ${readOnlyState}` : ""
      }...`,
    );

    const query = new URLSearchParams();
    if (contextId) {
      query.append("contextId", contextId);
    }
    if (asUserId) {
      query.append("asUserId", asUserId);
    }
    if (readOnlyState === true) {
      query.append("readOnlyState", "yes");
    }

    const queryString = query.toString();

    const res = await fetch(
      `${this.baseUrl}/${contentId}/play${
        queryString ? `?${queryString}` : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          "CSRF-Token": this.csrfToken ?? "",
        },
      },
    );
    if (!res || !res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  };

  list = async (): Promise<IContentListEntry[]> => {
    console.log(`ContentService: Listing content objects`);
    const result = await fetch(this.baseUrl, {
      headers: {
        "Content-Type": "application/json",
        "CSRF-Token": this.csrfToken ?? "",
      },
    });
    if (result.ok) {
      return result.json();
    }
    throw new Error(
      `Request to REST endpoint returned ${result.status} ${
        result.statusText
      }: ${await result.text()}`,
    );
  };

  deeplinkResource = async (
    contentId: string,
    title: string,
  ): Promise<{
    action: string;
    jwt: string;
  }> => {
    console.log(`ContentService: Creating deeplink for content ${contentId}`);

    const resource = {
      type: "ltiResourceLink",
      title: title,
      url: "https://h5p.megaschool.edu.vn/lti?contentId=" + contentId,
      custom: {
        resourceurl: "https://h5p.megaschool.edu.vn/lti?contentId=" + contentId,
        resourcename: title,
      },
    };

    const response = await fetch(`deeplink-resource`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "CSRF-Token": this.csrfToken ?? "",
        Authorization: "Bearer " + getLtikFromUrl(),
      },
      body: JSON.stringify(resource),
    });
    if (response.status !== 200) {
      throw new Error(
        `Error creating deeplink: ${response.status} ${response.statusText}`,
      );
    }

    const body = await response.json();
    console.log(body);
    return body;
  };

  listTeacher = async (): Promise<IContentListEntry[]> => {
    console.log(`ContentService: Listing content objects`);
    const result = await fetch(this.baseUrl + "/list-teacher", {
      headers: {
        "Content-Type": "application/json",
        "CSRF-Token": this.csrfToken ?? "",
      },
    });
    if (result.ok) {
      return result.json();
    }
    throw new Error(
      `Request to REST endpoint returned ${result.status} ${
        result.statusText
      }: ${await result.text()}`,
    );
  };

  save = async (
    contentId: string,
    requestBody: { library: string; params: any },
  ): Promise<{ contentId: string; metadata: IContentMetadata }> => {
    if (contentId !== "undefined") {
      console.log(`ContentService: Saving content ${contentId}`);
    } else {
      console.log(`ContentService: Saving new content.`);
    }
    const body = JSON.stringify(requestBody);
    try {
      const res =
        contentId !== "undefined"
          ? await fetch(`${this.baseUrl}/${contentId}`, {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                "CSRF-Token": this.csrfToken ?? "",
              },
              body,
            })
          : await fetch(this.baseUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "CSRF-Token": this.csrfToken ?? "",
              },
              body,
            });

      if (!res.ok) {
        const errorText = await res.text();
        if (
          errorText.includes("h5p-server:content-missing-create-permission")
        ) {
          throw new Error("h5p-server:content-missing-create-permission");
        }
        throw new Error(`${res.status} ${res.statusText} - ${errorText}`);
      }

      return res.json();
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error("An unknown error occurred while saving content");
    }
  };

  generateDownloadLink = (contentId: string): string =>
    `${this.baseUrl}/download/${contentId}`;

  setCsrfToken = (csrfToken): void => {
    this.csrfToken = csrfToken;
    console.log(this.csrfToken);
  };
  getCsrfToken = (): string | undefined => {
    return this.csrfToken;
  };
}
