import React, { useEffect, useRef, useState } from "react";
import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { Pencil, Save, X } from "lucide-react";
import { getLtikFromUrl } from "../helper";

const RoleBasedH5PComponent = ({ contentId, contentService }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [key, setKey] = useState(0);
  const baseUrl = "/h5p";
  const h5pEditor = useRef<H5PEditorUI | null>(null);

  useEffect(() => {
    fetchUserRole();
  }, []);

  const fetchUserRole = async () => {
    try {
      const response = await fetch("/lti/api/token-info", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getLtikFromUrl(),
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch user role");
      }
      const data = await response.json();
      setUserRole(data.role);
    } catch (error) {
      console.error("Error fetching user role:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPlay = async (contentId, contextId, asUserId, readOnlyState) => {
    console.log(`Getting information to play ${contentId}...`);
    const query = new URLSearchParams();
    if (contextId) query.append("contextId", contextId);
    if (asUserId) query.append("asUserId", asUserId);
    if (readOnlyState === true) query.append("readOnlyState", "yes");

    const queryString = query.toString();
    const res = await fetch(
      `${baseUrl}/${contentId}/play${queryString ? `?${queryString}` : ""}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (!res || !res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    }
    return res.json();
  };

  const handleEdit = () => {
    setIsEditing(true);
    setShowModal(true);
  };

  const handleSave = async () => {
    try {
      const returnData = await h5pEditor.current?.save();
      if (returnData) {
        console.log("Content saved successfully:", returnData);
        setShowModal(false);
        setIsEditing(false);
        setKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      console.error("Error saving content:", error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIsEditing(false);
  };

  const handleXAPIStatement = async (statement, context, event) => {
    console.log("xAPI Statement:", statement);
    console.log("Context:", context);
    console.log("Event:", event);

    if (
      (statement.verb.id === "http://adlnet.gov/expapi/verbs/answered" ||
        statement.verb.id === "http://adlnet.gov/expapi/verbs/completed") &&
      userRole === "student"
    ) {
      const score = statement.result?.score?.scaled || 0;
      const normalizedScore = Math.round(score * 100);

      const activityId = statement.object.id;
      const activityType = statement.object.definition.type;
      const interactionType = statement.object.definition.interactionType;
      const userResponse = statement.result.response;
      const duration = statement.result.duration;

      try {
        const response = await fetch(
          "https://h5p.megaschool.edu.vn/lti/grade",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + getLtikFromUrl(),
            },
            body: JSON.stringify({
              grade: normalizedScore,
              activityId,
              activityType,
              interactionType,
              userResponse,
              duration,
            }),
          },
        );

        if (!response.ok) {
          throw new Error("Failed to submit grade");
        }

        const result = await response.json();
        console.log("Grade submitted successfully:", result);
      } catch (error) {
        console.error("Error submitting grade:", error);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-4">Loading...</div>
    );
  }

  if (!contentId) {
    return <div>No content ID provided.</div>;
  }

  return (
    <div className="w-full max-w-3xl mx-auto">
      {userRole === "student" ||
      userRole === "teacher" ||
      userRole === "admin" ? (
        <>
          <H5PPlayerUI
            key={key}
            contentId={contentId}
            loadContentCallback={getPlay}
            onInitialized={() => setIsLoading(false)}
            onxAPIStatement={handleXAPIStatement}
            readOnlyState={userRole === "student"}
          />
          {(userRole === "teacher" || userRole === "admin") && (
            <button
              onClick={handleEdit}
              className="mt-3 inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
            >
              <Pencil className="w-4 h-4 mr-2" />
              Chỉnh sửa
            </button>
          )}
        </>
      ) : (
        <div>Bạn không có quyền.</div>
      )}

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          {/* Backdrop */}
          <div
            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
            onClick={handleCloseModal}
          />

          {/* Modal content */}
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
              {/* Header */}
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="flex items-center justify-between pb-4 border-b">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Chỉnh sửa thông tin
                  </h3>
                  <button
                    onClick={handleCloseModal}
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>

                {/* Content */}
                {isEditing && (
                  <div className="mt-4 min-h-[400px]">
                    <H5PEditorUI
                      ref={h5pEditor}
                      contentId={contentId}
                      loadContentCallback={contentService.getEdit}
                      saveContentCallback={contentService.save}
                    />
                  </div>
                )}
              </div>

              {/* Footer */}
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
                <button
                  onClick={handleSave}
                  className="inline-flex w-full justify-center items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 sm:ml-3 sm:w-auto"
                >
                  <Save className="w-4 h-4 mr-2" />
                  Lưu thay đổi
                </button>
                <button
                  onClick={handleCloseModal}
                  className="mt-3 inline-flex w-full justify-center items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  <X className="w-4 h-4 mr-2" />
                  Đóng
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleBasedH5PComponent;
