import React, { useEffect, useRef, useState } from "react";
import {
  Check,
  Download,
  Edit2,
  Link,
  Play,
  Plus,
  Save,
  Trash2,
  X,
} from "lucide-react";
import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";
import { IContentListEntry, IContentService } from "../services/ContentService";

interface ContentListEntryProps {
  contentService: IContentService;
  data: IContentListEntry;
  onDelete: (content: IContentListEntry) => void;
  onDeeplink: (contentId: string, title: string) => Promise<void>;
  onDiscard: (content: IContentListEntry) => void;
  onSaved: (data: IContentListEntry) => void;
  generateDownloadLink: (contentId: string) => string;
  isDeeplink: boolean;
  initialEditMode?: boolean;
}

const ContentListEntryComponent: React.FC<ContentListEntryProps> = ({
  contentService,
  data,
  onDelete,
  onDiscard,
  onSaved,
  generateDownloadLink,
  onDeeplink,
  isDeeplink,
  initialEditMode = false,
}) => {
  const [state, setState] = useState({
    editing: false,
    playing: false,
    saving: false,
    saved: false,
    loading: true, // Set to true initially
    saveErrorMessage: "",
    saveError: false,
    readOnlyState: false,
    asUserId: undefined,
    contextId: undefined,
    noCreatePermission: false,
    showModal: false,
  });

  const h5pEditor = useRef<H5PEditorUI>(null);
  const h5pPlayer = useRef<H5PPlayerUI>(null);

  useEffect(() => {
    if (initialEditMode) {
      openModal("edit");
    }
  }, [initialEditMode]);

  const openModal = (mode: "edit" | "play") => {
    setState((s) => ({
      ...s,
      showModal: true,
      editing: mode === "edit",
      playing: mode === "play",
      loading: true, // Reset loading state when opening modal
    }));
  };

  const closeModal = () => {
    setState((s) => ({
      ...s,
      showModal: false,
      editing: false,
      playing: false,
      loading: true, // Reset loading state when closing
    }));

    if (isNew() && !state.saved) {
      onDiscard(data);
    }
  };

  const save = async () => {
    setState((s) => ({ ...s, saving: true, noCreatePermission: false }));
    try {
      const returnData = await h5pEditor.current?.save();
      if (returnData) {
        const savedData = {
          contentId: returnData.contentId,
          mainLibrary: returnData.metadata.mainLibrary,
          title: returnData.metadata.title,
          originalNewKey: data.originalNewKey,
        };

        await onSaved(savedData);
        setState((s) => ({ ...s, saved: true, saving: false }));
        closeModal();
      }
    } catch (error) {
      if (
        error instanceof Error &&
        error.message.includes("h5p-server:content-missing-create-permission")
      ) {
        setState((s) => ({
          ...s,
          saving: false,
          saved: false,
          noCreatePermission: true,
        }));
      } else {
        onSaveError(error);
      }
    }
  };

  const onSaveError = (error: any) => {
    const errorMessage =
      error === "h5p-server:content-missing-create-permission"
        ? "Bạn không có quyền thực hiện hành động này"
        : error?.message || "An unknown error occurred";

    setState((s) => ({
      ...s,
      saving: false,
      saved: false,
      saveError: true,
      saveErrorMessage: errorMessage,
    }));

    setTimeout(() => setState((s) => ({ ...s, saveError: false })), 5000);
  };

  const onSavedCallback = () => {
    setState((s) => ({ ...s, saving: false, saved: true }));
  };

  const isNew = () => data.contentId === "new";

  const handleDeeplink = () => {
    onDeeplink(data.contentId, data.title);
  };

  const handleEditorLoaded = () => {
    setState((s) => ({ ...s, loading: false }));
  };

  const renderH5PEditor = () => {
    // For new items, don't pass contentId at all
    if (data.contentId === "new") {
      return (
        <H5PEditorUI
          ref={h5pEditor}
          contentId={"undefined"}
          loadContentCallback={contentService.getEdit}
          saveContentCallback={contentService.save}
          onSaved={onSavedCallback}
          onLoaded={handleEditorLoaded}
          onSaveError={onSaveError}
        />
      );
    }

    // For existing items, pass the contentId
    return (
      <H5PEditorUI
        ref={h5pEditor}
        contentId={data.contentId}
        loadContentCallback={contentService.getEdit}
        saveContentCallback={contentService.save}
        onSaved={onSavedCallback}
        onLoaded={handleEditorLoaded}
        onSaveError={onSaveError}
      />
    );
  };

  const isButtonDisabled = () => {
    // Only disable the save button if we're actually saving
    return state.saving;
  };

  return (
    <>
      {(!isNew() || !initialEditMode) && (
        <div className="border rounded-lg p-4">
          <div className="flex justify-between items-start">
            <div className="flex-1">
              <h4 className="font-medium text-gray-900">{data.title}</h4>
              <div className="flex gap-4 mt-1 text-sm text-gray-600">
                {data.mainLibrary && (
                  <div className="flex items-center gap-1">
                    <span>Type:</span>
                    <span>{data.mainLibrary}</span>
                  </div>
                )}
                {data.contentId !== "new" && (
                  <div className="flex items-center gap-1">
                    <span>ID:</span>
                    <span>{data.contentId}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex gap-2">
              {!isNew() ? (
                <>
                  <button
                    onClick={() => openModal("play")}
                    className="p-2 hover:bg-gray-100 rounded-full"
                    title="Phát"
                  >
                    <Play size={20} className="text-gray-600" />
                  </button>
                  <button
                    onClick={() => openModal("edit")}
                    className="p-2 hover:bg-gray-100 rounded-full"
                    title="Chỉnh sửa"
                  >
                    <Edit2 size={20} className="text-gray-600" />
                  </button>
                  <a
                    href={generateDownloadLink(data.contentId)}
                    className="p-2 hover:bg-gray-100 rounded-full"
                    title="Tải xuống"
                  >
                    <Download size={20} className="text-gray-600" />
                  </a>
                  {isDeeplink && (
                    <button
                      onClick={handleDeeplink}
                      className="p-2 hover:bg-gray-100 rounded-full"
                      title="Tạo Deeplink"
                    >
                      <Link size={20} className="text-gray-600" />
                    </button>
                  )}
                  <button
                    onClick={() => onDelete(data)}
                    className="p-2 hover:bg-gray-100 rounded-full"
                    title="Xóa"
                  >
                    <Trash2 size={20} className="text-gray-600" />
                  </button>
                </>
              ) : (
                <button
                  onClick={() => openModal("edit")}
                  className="p-2 hover:bg-gray-100 rounded-full"
                  title="Tạo mới"
                >
                  <Plus size={20} className="text-gray-600" />
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {state.showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white w-full h-full flex flex-col">
            <div className="flex items-center justify-between p-4 border-b">
              <h3 className="text-lg font-medium">
                {state.editing
                  ? isNew()
                    ? "Tạo nội dung mới"
                    : "Chỉnh sửa nội dung"
                  : "Phát nội dung"}
              </h3>
              <button
                onClick={closeModal}
                className="p-2 hover:bg-gray-100 rounded-full"
              >
                <X size={20} />
              </button>
            </div>

            <div className="flex-1 overflow-auto p-4">
              {state.noCreatePermission && (
                <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg mb-4">
                  You don't have permission to create new content. Please
                  contact your administrator.
                </div>
              )}

              {state.editing && (
                <div className="h-full">{renderH5PEditor()}</div>
              )}

              {state.playing && (
                <div className={`h-full ${state.loading ? "opacity-50" : ""}`}>
                  <H5PPlayerUI
                    ref={h5pPlayer}
                    contentId={data.contentId}
                    contextId={state.contextId}
                    asUserId={state.asUserId}
                    readOnlyState={state.readOnlyState}
                    loadContentCallback={contentService.getPlay}
                    onInitialized={() =>
                      setState((s) => ({ ...s, loading: false }))
                    }
                    onxAPIStatement={(
                      statement: any,
                      context: any,
                      event: any,
                    ) => console.log(statement, context, event)}
                  />
                </div>
              )}
            </div>

            <div className="flex justify-end gap-2 p-4 border-t">
              {state.editing && (
                <button
                  onClick={save}
                  disabled={isButtonDisabled()}
                  className={`flex items-center gap-2 px-4 py-2 rounded-lg
                                        ${
                                          isButtonDisabled()
                                            ? "bg-blue-300"
                                            : "bg-blue-600 hover:bg-blue-700"
                                        } text-white`}
                >
                  {state.saving ? (
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  ) : (
                    <Save size={20} />
                  )}
                  Lưu
                  {state.saved && <Check size={20} />}
                </button>
              )}
              <button
                onClick={closeModal}
                className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50"
              >
                <X size={20} />
                Đóng
              </button>
            </div>

            {state.saveError && (
              <div className="fixed bottom-4 right-4 bg-red-100 border border-red-200 text-red-700 px-4 py-3 rounded-lg shadow-lg">
                {state.saveErrorMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ContentListEntryComponent;
