import React, { useEffect, useState } from "react";
import { ContentService } from "../services/ContentService";
import { getLtikFromUrl } from "../helper";

interface LoginProps {
  contentService: ContentService;
  onLoggedIn: (role: string) => void;
  onLoggedOut: () => void;
}

interface UserInfo {
  id: string;
  username: string;
  email: string;
  name: string;
  role: string;
  csrfToken: string;
}

const Login: React.FC<LoginProps> = ({
  contentService,
  onLoggedIn,
  onLoggedOut,
}) => {
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const [loginMessage, setLoginMessage] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await fetch("/lti/api/token-info", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getLtikFromUrl(),
        },
      });

      if (response.status === 200) {
        const userInfo: UserInfo = await response.json();
        setUserInfo(userInfo);
        setLoginMessage(undefined);

        if (userInfo.csrfToken) {
          contentService.setCsrfToken(userInfo.csrfToken);
          localStorage.setItem("csrfToken", userInfo.csrfToken);
        }

        onLoggedIn(userInfo.role);
      } else {
        const errorText = await response.text();
        setUserInfo(undefined);
        setLoginMessage(`Xác thực thất bại: ${errorText}`);
        onLoggedOut();
      }
    } catch (error) {
      setUserInfo(undefined);
      setLoginMessage(
        `Lỗi khi lấy thông tin người dùng: ${error instanceof Error ? error.message : "Đã xảy ra lỗi"}`,
      );
      onLoggedOut();
    }
  };

  const isAdministrator = (role: string): boolean => {
    return role.toLowerCase() === "admin";
  };
  return (
    <div className="login-component">
      {userInfo ? (
        <div>{isAdministrator(userInfo.role) && <></>}</div>
      ) : (
        <h2>Chưa đăng nhập</h2>
      )}
      {loginMessage && <h2>{loginMessage}</h2>}
    </div>
  );
};

export default Login;
